<template>
  <div>
    <form @submit.prevent="updateNews" class="mt-4">
      <div class="row">
        <div class="col-6">
          <div class="mb-3">
            <label for="title" class="form-label"> Titre :</label>
            <input
              type="text"
              id="title"
              v-model="newsArticle.title"
              class="form-control"
              required
            />
          </div>

          <div class="mb-3">
            <label for="publicationDate" class="form-label">
              Date publication :</label
            >
            <input
              type="date"
              id="publicationDate"
              v-model="newsArticle.publicationDate"
              class="form-control"
              required
            />
          </div>

          <div class="mb-3">
            <label for="content" class="form-label"> Text :</label>
            <textarea
              id="content"
              v-model="newsArticle.content"
              class="form-control"
              rows="15"
              required
            ></textarea>
          </div>

          <div class="mb-3">
            <label for="photo" class="form-label"> Photo:</label>
            <input
              type="file"
              id="photo"
              @change="handleImageUpload"
              class="form-control"
              accept="image/*"
            />
          </div>

          <div class="mb-3" v-if="newsArticle.image">
            <label class="form-label"> Photo:</label>
            <img
              :src="imageURL"
              alt="Uploaded Photo"
              class="img-thumbnail"
              style="max-width: 100%"
            />
          </div>
        </div>
        <div class="col-6">
          <div class="mb-3">
            <label for="title" class="form-label"> Titre Arabe :</label>
            <input
              type="text"
              id="title"
              v-model="newsArticle.titleAr"
              class="form-control"
              required
            />
          </div>

          <div class="mb-3">
            <label for="publicationDate" class="form-label">
              Date publication :</label
            >
            <input
              type="date"
              id="publicationDate"
              v-model="newsArticle.publicationDate"
              class="form-control"
              disabled
            />
          </div>

          <div class="mb-3">
            <label for="content" class="form-label"> Text Arabe :</label>
            <textarea
              id="content"
              v-model="newsArticle.contentAr"
              class="form-control"
              rows="15"
              required
            ></textarea>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <div>
            <span class="fw-bold fs-4">
              {{ newsArticle.title }}
            </span>
          </div>
          <div class="w-100">
            <img :src="newsArticle.imageUrl" alt="" class="w-100" />
          </div>

          <div class="">
            <br />
            {{ newsArticle.publicationDate }}
          </div>
          <div class="">
            <p v-html="newsArticle.content"></p>
          </div>
        </div>
        <div class="col-6 text-end">
          <div>
            <span class="fw-bold fs-4">
              {{ newsArticle.titleAr }}
            </span>
          </div>
          <div class="w-100">
            <img :src="newsArticle.imageUrl" alt="" class="w-100" />
          </div>

          <div class="">
            <br />
            {{ newsArticle.publicationDate }}
          </div>
          <div class="">
            <p v-html="newsArticle.contentAr"></p>
          </div>
        </div>
      </div>

      <button type="submit" class="btn btn-primary">
        Enregistrer l'article
      </button>
    </form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {};
  },
  beforeCreate() {
    this.$store.dispatch("news/find", this.$route.params.id);
  },
  computed: {
    ...mapGetters("news", { newsArticle: "getNewsArticle" }),

    photoURL() {
      return this.newsArticle.photo
        ? URL.createObjectURL(this.newsArticle.photo)
        : "";
    },
  },
  methods: {
    updateNews() {
      this.$store.dispatch("news/update", this.newsArticle);
    },
    handleImageUpload(event) {
      const file = event.target.files[0];
      this.newsArticle.image = file;
      this.newsArticle.imageUrl = URL.createObjectURL(file); // Create a URL for the image
    },
  },
};
</script>
